import {
  TOKEN_CURRENT,
  ROLE_ID,
} from '@/constants/index'
import storage from './storage'

const getUserDataFromStorage = () => ({
  tokenCurrent: storage.getStorage(TOKEN_CURRENT),
  roleId: storage.getStorage(ROLE_ID),
})

export default getUserDataFromStorage
