import resource from '../resource'

const endpoints = {
    fakultas: '/user/master/fakultas',
    jurusan: '/user/master/jurusan',

    role: '/user/web/role/master',

}

export default {

    fakultas: () => {
        const request = resource.get(`${endpoints.fakultas}`)
        return request
    },
    jurusan: () => {
        const request = resource.get(`${endpoints.jurusan}`)
        return request
    },
    role: () => {
        const request = resource.get(`${endpoints.role}`)
        return request
    },

}
