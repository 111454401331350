import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.mixin({
  methods: {
    toastError(title = 'Ups') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
    },
    handleMsgSuccess(title = 'Berhasil menyimpan') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    sweetAlertSuccess(msg = 'Berhasil') {
      this.$swal({
        title: 'Success!',
        text: msg,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    handleMsgErrors(error, title = 'Gagal menyimpan') {
      if (error.response.status === 422) {
        if (error.response.data.error_type === 'validation') {
          const self = this
          error.response.data.errors.forEach((err, i) => {
            setTimeout(() => {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message,
                  icon: 'BellIcon',
                  variant: 'danger',
                  setTimeout: '5000',
                },
              })
            }, 3000 * i)
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
              setTimeout: '3000',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            variant: 'danger',
            setTimeout: '3000',
          },
        })
      }
    },
  },
})
