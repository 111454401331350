import API from '@/api'
import storage from '@/helpers/storage'

export default {
  namespaced: true,
  state: {
    userLoggedIn: null, // ex value: { id, nama_panjang, role_id, ... }
  },
  mutations: {
    UPDATE_USER_LOGGED_IN(state, val) {
      state.userLoggedIn = val
    },
  },
  actions: {
    checkToken({ commit }) {
      return new Promise(res => {
        (async () => {
          try {
            const { data } = await API.auth.checkToken()
            commit('UPDATE_USER_LOGGED_IN', data)
            return res(true)
          } catch (error) {
            commit('UPDATE_USER_LOGGED_IN', null)
            storage.clearStorage()
            return res()
          }
        })()
      })
    },
    logout({ commit }) {
      return new Promise(res => {
        (async () => {
          await API.auth.logout()
          commit('UPDATE_USER_LOGGED_IN', null)
          storage.clearStorage()
          return res()
        })()
      })
    },
  },
  getters: {
    isAuthenticated: state => !!state.userLoggedIn,
  },
}
