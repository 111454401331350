/* eslint-disable no-param-reassign */
import axios from '@/libs/axios'
import getDataFromStorage from '@/helpers/getDataFromStorage'

function createResource() {
  axios.interceptors.request.use(config => {
    // for intercept request or header request
    const token = getDataFromStorage().tokenCurrent

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    const {
      VUE_APP_X_UIC_VERSION,
      VUE_APP_X_API_VERSION,
      VUE_APP_X_UIC_PLATFORM,
      VUE_APP_X_UIC_DEVICE,
      // VUE_APP_X_UIC_CLIENTKEY,
    } = process.env

    config.headers['X-UIC-VERSION'] = VUE_APP_X_UIC_VERSION
    config.headers['X-API-VERSION'] = VUE_APP_X_API_VERSION
    config.headers['X-UIC-PLATFORM'] = VUE_APP_X_UIC_PLATFORM
    config.headers['X-UIC-DEVICE'] = VUE_APP_X_UIC_DEVICE
    // config.headers['X-UIC-CLIENTKEY'] = VUE_APP_X_UIC_CLIENTKEY

    return config
  }, error => Promise.reject(error))

  return axios
}

export default createResource()
