export default [
  {
    path: '/changePassword/:token',
    name: 'change-password',
    component: () => import('@/views/auth/ChangePassword.vue'),
    meta: {
      layout: 'full',
      authenticationPage: true,
    },
  },
  {
    path: '/verifyEmail/:token',
    name: 'verify-email',
    component: () => import('@/views/auth/VerifyEmail.vue'),
    meta: {
      layout: 'full',
      authenticationPage: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'full',
      authenticationPage: true,
    },
  },
  {
    path: '/verifyLink/:token',
    name: 'verify-link',
    component: () => import('@/views/auth/VerifyLink.vue'),
    meta: {
      layout: 'full',
      authenticationPage: true,
    },
  },

  {
    path: '/openApp',
    name: 'open-app',
    component: () => import('@/views/auth/OpenApp.vue'),
    meta: {
      layout: 'full',
      authenticationPage: true,
    },
  },
]
